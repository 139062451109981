import React from 'react';
import Game from './Game';
import './App.css';

const App = () => {
	return (
		<div className="App">
			<Game />
		</div>
	);
};

export default App;
