import React, { Component } from 'react';

class Home extends Component {
	render() {
		return (
			<div>
				<p>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
					inventore facere doloremque odit dignissimos autem quis quibusdam
					aliquam aut suscipit ea nemo ipsa sapiente dolores, minus eaque
					voluptates. Veritatis enim accusantium, sit eligendi sed vel deleniti
					magnam corrupti quis delectus blanditiis velit deserunt ex corporis
					obcaecati qui, fugiat unde impedit!
				</p>
				<p>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
					inventore facere doloremque odit dignissimos autem quis quibusdam
					aliquam aut suscipit ea nemo ipsa sapiente dolores, minus eaque
					voluptates. Veritatis enim accusantium, sit eligendi sed vel deleniti
					magnam corrupti quis delectus blanditiis velit deserunt ex corporis
					obcaecati qui, fugiat unde impedit!
				</p>
				<p>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
					inventore facere doloremque odit dignissimos autem quis quibusdam
					aliquam aut suscipit ea nemo ipsa sapiente dolores, minus eaque
					voluptates. Veritatis enim accusantium, sit eligendi sed vel deleniti
					magnam corrupti quis delectus blanditiis velit deserunt ex corporis
					obcaecati qui, fugiat unde impedit!
				</p>
			</div>
		);
	}
}

export default Home;
